<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Location
        b-col(md="4")
          b-form-group(
            :label="$t('label.Location')",
            :label-for="$t('label.location')",
            description=""
          )
            validation-provider(:name="$t('label.Location')", rules="required", v-slot="{ errors }")
              b-form-input(
                id="location",
                v-model="data.location",
                trim,
                :placeholder="$t('placeholder.name')"
              )
              small(class="text-danger") {{ errors[0] }}
        
        // Zone
        b-col(md="4", v-if="zoneAgencyFilter")
          b-form-group(
            :label="$t('label.Zone')",
            :label-for="$t('label.Zone')",
            description=""
          )
            v-select#zone(
              v-model="data.zone",
              :options="metadataFilterOptions.zone",
              :placeholder="$t('placeholder.zone')"
            )
        
        // Agency
        b-col(md="4", v-if="zoneAgencyFilter && !commitmentFunctionality")
          b-form-group(
            :label="$t('label.Agency')",
            :label-for="$t('label.Agency')",
            description=""
          )
            v-select#agency(
              v-model="data.agency",
              :options="metadataFilterOptions.agency",
              :placeholder="$t('placeholder.agency')"
            )

      // Button
      b-row
        b-col(
          cols="12"
          class="text-right"
        )
          b-button(
            variant="primary",
            :disabled="isSubmitting",
            @click="validateAndSubmit"
          )
            | {{ $t('message.submit') }}

</template>

<script>
// eslint-disable-next-line import/extensions
import { ref, toRefs, onMounted } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import vSelect from 'vue-select'

export default {
  name: 'Form',

  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  props: ['data', 'isSubmitting'],

  setup(props, { emit }) {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const form = ref(null)
    const { getMetadataForDropDown, metadataFilterOptions } = useCommon()
    const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false
    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const validateLocation = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateLocation()
      .then(() => emit('submit', toRefs(props.data)))
      .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    onMounted(async () => {
      getMetadataForDropDown({category: "filter"})
    })

    return {
      required,
      form,
      validateAndSubmit,
      metadataFilterOptions,
      zoneAgencyFilter,
      commitmentFunctionality
    }
  },
}
</script>

<style scoped>

</style>

import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useLocationEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const location = ref({})
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'location'

  const show = async () => {
    isSubmitting.value = true

    try {
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      location.value = item
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.location_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async data => {
    isSubmitting.value = true

    const payload = {
      location: data.location?.value || '',
    }

    if (data.zone?.value) payload.zone = data.zone.value
    if (data.agency?.value) payload.agency = data.agency.value

    try {
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.location_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.location_update_error'))
    } finally {
      router.push({ name: 'organization-location-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    location,
    isSubmitting,
  }
}
